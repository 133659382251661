// LayersComponent.js
import React, { useRef, useEffect } from "react";
import "./styles.css";

function LayersComponent() {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      const offsetX = (clientX - centerX) / centerX;
      const offsetY = (clientY - centerY) / centerY;

      const layers = document.querySelectorAll(".layer");
      layers.forEach((layer, index) => {
        const movementFactor = (index + 1) * 0.05; // 0.05, 0.1, 0.15, 0.2...
        const translateX = offsetX * movementFactor * 50; // 50 to intensify the effect.
        const translateY = offsetY * movementFactor * 50;
        layer.style.transform = `translate(${translateX}px, ${translateY}px)`;
      });
    };

    containerRef.current.addEventListener("mousemove", handleMouseMove);

    return () => {
      containerRef.current.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="container" ref={containerRef}>
      <div className="layer">Christian King</div>
      <div className="layer">Christian King</div>
      <div className="layer">Christian King</div>
      <div className="layer">Christian King</div>
      <div className="layer">Christian King</div>
    </div>
  );
}

export default LayersComponent;

import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring, config, useTrail } from "react-spring";
import styled from "styled-components";
import keyframes from "styled-components";
import LayersComponent from "./LayersComponent";
import { createGlobalStyle } from "styled-components";
import GlobalStyles from "./GlobalStyle";

const gradientColors = ["#534666", "#138086", "#CD7672", "#DC8665", "#EEB462"];

const Body = styled.body`
  overflow: hidden;
`;

const ButtonLink = styled.a`
  display: flex; // Enable flex layout
  justify-content: center; // Center content horizontally
  align-items: center; // Center content vertically
  text-decoration: none; // Remove underline from link
  width: 10%; // Same width as Button

  @media (max-width: 768px) {
    width: 100%; // Full width on small screens
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 2px solid black;
  color: black;
  font-size: 1rem;
  padding: 10px 20px;
  margin-top: 5%;
  width: 200px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    width: 200px;
    padding: 8px 16px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30%;
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  z-index: 2;

  // By default (for mobile and smaller screens), stack buttons vertically
  flex-direction: column;

  // Adjust layout for larger screens (for example, above 768px)
  @media (min-width: 768px) {
    margin-top: 10%;
    flex-direction: row; // This will display buttons in a row on larger screens
  }
`;

const Container = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 2% 5%; // Added some padding for smaller screens
`;

const Title = styled(animated.h1)`
  font-size: 6rem; // Bigger size for desktop
  color: #1f0080;
  position: relative;
  z-index: 1;
  font-family: "Roboto", sans-serif, bold;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 5rem;
  }

  @media (max-width: 1000px) {
    font-size: 4rem;
  }

  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.h2`
  position: absolute;
  font-size: 2.5rem;
  color: #534666;
  font-family: "Roboto", sans-serif, bold;
  margin-top: -8rem; // Some space between the title and subtitle

  @media (max-width: 1200px) {
    font-size: 2.2rem;
  }

  @media (max-width: 1000px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: -12rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column; // Stacking elements vertically
  align-items: center; // Centering children horizontally
  text-align: center;
  z-index: 2; // Ensure it's above the canvas
`;

const TitleLayer = styled(Title)`
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 600px) {
    top: 25%;
  }
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`;

function getHrefForButton(button) {
  switch (button) {
    case "Resume":
      return "resume.pdf";
    case "Github":
      return "https://github.com/JavaNoTea";
    case "LinkedIn":
      return "https://www.linkedin.com/in/christian-king-comp-sci/";
    case "Email":
      return "mailto:christiankingcompsci@gmail.com";
    default:
      return "#";
  }
}

function NodeCanvas() {
  const canvasRef = useRef(null);
  const nodes = [];
  const maxDistance = 150;
  const damping = 0.99;

  function getColorFromSpeed(speed, maxSpeed) {
    const colorIndex = Math.min(
      Math.floor((speed / maxSpeed) * gradientColors.length),
      gradientColors.length - 1
    );
    return gradientColors[colorIndex];
  }

  function Node(x, y) {
    this.x = x;
    this.y = y;
    this.vx = Math.random() * 2 - 1;
    this.vy = Math.random() * 2 - 1;
    this.radius = Math.random() * 4 + 1;
  }

  Node.prototype.draw = function (ctx) {
    const speed = Math.sqrt(this.vx * this.vx + this.vy * this.vy);
    const color = getColorFromSpeed(speed, 4);
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.closePath();
  };

  Node.prototype.updatePosition = function (
    ctx,
    width,
    height,
    mouseX,
    mouseY
  ) {
    if (this.x < 0 || this.x > width) this.vx = -this.vx;
    if (this.y < 0 || this.y > height) this.vy = -this.vy;

    const dx = mouseX - this.x;
    const dy = mouseY - this.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const speed = Math.sqrt(this.vx * this.vx + this.vy * this.vy);
    const color = getColorFromSpeed(speed, 4);

    if (distance < maxDistance) {
      this.vx += dx / 150;
      this.vy += dy / 150;
      ctx.beginPath();
      ctx.strokeStyle = color; // setting the line color to the node's color
      ctx.lineWidth = 0.7;
      ctx.moveTo(this.x, this.y);
      ctx.lineTo(mouseX, mouseY);
      ctx.stroke();
    }

    this.vx *= damping;
    this.vy *= damping;

    this.x += this.vx;
    this.y += this.vy;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    for (let i = 0; i < (window.innerWidth * window.innerHeight) / 10000; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      nodes.push(new Node(x, y));
    }

    let mouseX = -100;
    let mouseY = -100;

    const updateCanvas = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < nodes.length; i++) {
        nodes[i].draw(ctx);
        nodes[i].updatePosition(
          ctx,
          canvas.width,
          canvas.height,
          mouseX,
          mouseY
        );
      }

      animationFrameId = window.requestAnimationFrame(updateCanvas);
    };

    updateCanvas();

    document.addEventListener("mousemove", (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    });

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return <Canvas ref={canvasRef} />;
}

const useTypingEffect = (text, typingSpeed) => {
  const [outputText, setOutputText] = React.useState("");
  React.useEffect(() => {
    if (outputText.length < text.length) {
      setTimeout(() => {
        setOutputText(text.slice(0, outputText.length + 1));
      }, typingSpeed);
    }
  }, [outputText, text, typingSpeed]);

  return outputText;
};

const LandingPage = () => {
  document.body.style.overflow = "hidden";
  const buttons = ["Resume", "Github", "LinkedIn", "Email"];
  const buttonAnimations = useTrail(buttons.length, {
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(30px)" },
    config: config.gentle,
    delay: 200 + "Christian King".length * 100 + "AI/ML Engineer".length * 100,
  });

  const titleProps = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-50px)" },
    delay: 200,
  });

  const subtitleProps = useSpring({
    opacity: 1,
    transform: "translateY(-50%)",
    from: { opacity: 0 },
    delay: 200 + "Christian King".length * 100, // Start after title is typed
    config: config.slow,
  });

  const titleText = useTypingEffect("Christian King", 100);
  const subtitleText = useTypingEffect("Software Engineer", 100);

  const [offsets, setOffsets] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!containerRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      const offsetX = e.clientX - centerX;
      const offsetY = e.clientY - centerY;

      const factors = [0, 0.004, 0.008, 0.012, 0.016];
      const newOffsets = factors.map((factor) => [
        offsetX * factor,
        offsetY * factor,
      ]);
      setOffsets(newOffsets);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <Container ref={containerRef}>
      <GlobalStyles />
      <NodeCanvas />
      <TitleContainer>
        {offsets.map(([x, y], index) => (
          <TitleLayer
            key={index}
            style={{
              ...titleProps,
              transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
              color: gradientColors[index],
            }}
          >
            {titleText}
          </TitleLayer>
        ))}
      </TitleContainer>
      <Subtitle style={subtitleProps}>{subtitleText}</Subtitle>

      <ButtonContainer>
        {buttons.map((button, index) => (
          <ButtonLink
            href={getHrefForButton(button)}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button style={buttonAnimations[index]}>{button}</Button>
          </ButtonLink>
        ))}
      </ButtonContainer>
    </Container>
  );
};

export default LandingPage;
